import { Service } from '@/services/service'

const PATH = 'ai/'
const COMPLETIONS_PATH = PATH + 'completion/'
const ASSISTANTS_PATH = PATH + 'assistant/'
const WEB_CHAT_ASSISTANTS_PATH = 'web-chat/assistant/'
const WEB_CHAT_PATH = PATH + 'web-chat/'

class AiService extends Service {
  getMailingSubjectCompletions (params) {
        return this.post(COMPLETIONS_PATH + 'mail/subject', params)
  }

  getSmsCompletions (params) {
    return this.post(COMPLETIONS_PATH + 'sms/text-message', params)
  }

  getMailingTextCompletions (params) {
    return this.post(COMPLETIONS_PATH + 'mail/text', params)
  }

  getVoiceTextCompletions (params) {
    return this.post(COMPLETIONS_PATH + 'voice/text', params)
  }

  getVoiceSmsCompletions (params) {
    return this.post(COMPLETIONS_PATH + 'voice/sms', params)
  }

  getWhatsAppBodyTextCompletions (params) {
    return this.post(COMPLETIONS_PATH + 'whatsapp/text', params)
  }

  getWhatsAppAutoAnswerTextCompletions (params) {
    return this.post(COMPLETIONS_PATH + 'whatsapp/auto-answer', params)
  }

  getWhatsAappInboxTextCompletions (params) {
    return this.post(COMPLETIONS_PATH + 'whatsapp/inbox-text', params)
  }

  save (params) {
    return this.post(ASSISTANTS_PATH + 'save', params)
  }

  changeStatus (params) {
    return this.post(ASSISTANTS_PATH + 'change-status', params)
  }

  getAssistants (params) {
    return this.get(ASSISTANTS_PATH + 'list', params)
  }

  getHasCurrentUserCreatedWithMainApiKey (params) {
    return this.get(ASSISTANTS_PATH + 'has-created-main-api-key', params)
  }

  totalContactsByAssistantId (params) {
    return this.post(ASSISTANTS_PATH + 'total-contacts-by-assistant-id', params)
  }

  deleteAssitant (params) {
    return this.post(ASSISTANTS_PATH + 'delete', params)
  }

  createThreadWithParams (params) {
    return this.post(ASSISTANTS_PATH + 'create-thread', params)
  }

  createMessageWithParams (params) {
    return this.post(ASSISTANTS_PATH + 'create-message', params)
  }

  createRun (params) {
    return this.post(ASSISTANTS_PATH + 'create-run', params)
  }

  checkRunById (params) {
    return this.post(ASSISTANTS_PATH + 'check-run', params)
  }

  executeToolFunction (params) {
    return this.post(ASSISTANTS_PATH + 'tool-function-execute', params)
  }

  checkLastMessageNotEnoughInfo (params) {
    return this.post(ASSISTANTS_PATH + 'check-last-message-not-enough-info', params)
  }


  getMessageById (params) {
    return this.post(ASSISTANTS_PATH + 'get-message-by-id', params)
  }

  getMessages (params) {
    return this.post(ASSISTANTS_PATH + 'get-messages', params)
  }

  getCreationData () {
    return this.get(ASSISTANTS_PATH + 'creation-data')
  }

  getAssistantsSimpleList () {
    return this.get(ASSISTANTS_PATH + 'assistants')
  }

  changeAgent (params) {
    return this.post(ASSISTANTS_PATH + 'change-agent', params)
  }

  getWebUrlContent (params) {
    return this.post(ASSISTANTS_PATH + 'web-url-content', params)
  }

  saveFiles (params) {
    return this.post(ASSISTANTS_PATH + 'save-files', params)
  }

  createFileUrl (params) {
    return this.post(ASSISTANTS_PATH + 'create-file-url', params)
  }

  saveFileAudience (params) {
    return this.post(ASSISTANTS_PATH + 'save-file-audience', params)
  }

  removeFile (params) {
    return this.post(ASSISTANTS_PATH + 'remove-file', params)
  }

  // web chat
  webChatCreateThreadWithParams (params) {
    return this.post(WEB_CHAT_ASSISTANTS_PATH + 'create-thread', params)
  }

  webChatCreateMessageWithParams (params) {
    return this.post(WEB_CHAT_ASSISTANTS_PATH + 'create-message', params)
  }

  webChatCreateRun (params) {
    return this.post(WEB_CHAT_ASSISTANTS_PATH + 'create-run', params)
  }

  webChatCheckRunById (params) {
    return this.post(WEB_CHAT_ASSISTANTS_PATH + 'check-run', params)
  }

  webChatExecuteToolFunction (params) {
    return this.post(WEB_CHAT_ASSISTANTS_PATH + 'tool-function-execute', params)
  }

  webChatGetMessages (params) {
    return this.post(WEB_CHAT_ASSISTANTS_PATH + 'get-messages', params)
  }

  webChatSaveLastMessage (params) {
    return this.post(WEB_CHAT_ASSISTANTS_PATH + 'save-last-message', params)
  }

  getAssistantByUniqueKey (params) {
    return this.post(WEB_CHAT_ASSISTANTS_PATH + 'by-unique-key', params)
  }


  getPrivateWebChatConversations (params) {
    return this.get(WEB_CHAT_PATH + 'conversations', params)
  }

  getByBaseMessageId (params) {
    return this.get(WEB_CHAT_PATH + 'conversations/by-base-message-id', params)
  }

  getPrivateWebChatMessages (params) {
    return this.post(WEB_CHAT_PATH + 'messages', params)
  }


  getWebChatMessagesByUniqueId (params) {
    return this.post(WEB_CHAT_ASSISTANTS_PATH + 'web-chat-messages', params)
  }

  savePublicWebChatMessage (params) {
    return this.post('web-chat/conversations/create-message', params)
  }

  reactToMessage (params) {
    return this.post('web-chat/conversations/react-to-message', params)
  }

  removeMessageReaction (params) {
    return this.post('web-chat/conversations/remove-message-reaction', params)
  }

  savePublicWebChatMessageFromInbox (params) {
    return this.post('ai/web-chat/conversations/create-message-from-inbox', params)
  }

  changeAssistantToConversation (params) {
    return this.post('ai/web-chat/conversations/change-assistant-to-conversation', params)
  }

  closeConversation (params) {
    return this.post('web-chat/conversations/close', params)
  }

  getContactsRelatedToAssistant (params) {
    return this.post(ASSISTANTS_PATH + 'contacts-related-to-assistant', params)
  }

  reAssignContacts (params) {
    return this.post(ASSISTANTS_PATH + 'assign-contacts', params)
  }

  openConversation (params) {
    return this.post('web-chat/conversations/open', params)
  }
}

export default new AiService()
